import {
  LinkParameterSlug,
  IndustryOrMaterial,
  QualitySystem,
  TypeOfTesting,
} from '../../logic/catalog'
import { territoriesEn } from '../../logic/country'
import { ProductFilterType } from '../../logic/shared-types'
import { StaticPage } from '../../logic/staticPage'

export default {
  aboutUs: {
    partnerQuestion: 'Want to join our partner network?',
  },
  address: {
    openingHours: {
      helsinki: 'Mon–Fri 7:45–15:45',
      sampleReception: 'Sample reception',
    },
  },
  aria: {
    linkedInPage: 'LinkedIn page',
    search: 'Search',
  },
  common: {
    askForOffer: 'Ask for an offer',
    askMore: 'Ask more',
    cancel: 'Cancel',
    contactUs: 'Contact us',
    createdAt: 'Created',
    edit: 'Edit',
    formSubmitError:
      'Failed to submit the form. Please contact us through another channel.',
    getStarted: 'Get started today',
    goToBlog: 'Read our blog',
    goToShop: 'See our services',
    goToSolutions: 'See our solutions',
    homePage: 'Home page',
    inactiveEmployee: 'former employee',
    keepReading: 'Keep reading',
    orContact: 'or contact us at',
    partner: 'Become a partner',
    readMore: 'Learn More',
    save: 'Save',
  },

  banner: {
    one: {
      primary: 'Leave your tests to us',
      secondary: 'and focus on what you do best',
    },
    two: {
      primary: 'Your partner in all your',
      secondary: 'measurement and testing needs.',
    },
  },
  blogLeadForm: {
    ctaButton: 'Send',
    emailLabel: 'Your work email',
    error:
      'Failed to submit the form. Please contact us through another channel.',
    success: 'Thank you! Please check your email',
  },
  blogPostPage: {
    hero: {
      published: 'Published',
      updated: 'Updated',
    },
    tableOfContents: 'Table of contents',
  },
  campaign: {
    whyUs1: {
      long: 'get all your tests done in the same place.',
      short: 'Widest range of testing methods',
    },
    whyUs2: {
      long: 'for every test, we have sourced the best lab for that particular method, which means that you get the most accurate results and the best price.',
      short: 'Best laboratories',
    },
    whyUs3: {
      long: 'get help from method experts if you are unsure about standards, your testing needs or sample suitability.',
      short: 'Personal service',
    },
    whyUsTitle: 'Why choose Measurlabs?',
  },
  careers: {
    copyMessage: 'Copy link to the position',
    copySucces: 'Link copied to clipboard',
  },
  cart: {
    additionalDetails: {
      heading: 'Additional details',
      one: 'What are you looking to use the results for? Anything else we should know?',
      two: 'The better we understand your needs, the better testing service we can provide for you.',
    },
    additionalTests:
      'You can add additional testing services to the same order:',
    additionalTestsBtn: '+ Add more testing services',
    nextStep: 'Next: Billing',
    removeItem: 'Remove item from the analysis order',
    samplesHeading: 'Samples ({{count}})',
    serviceFee: 'Service fee',
    serviceFeeDescription:
      'The fee includes project management, reporting and sample handling, storage and disposal. It is charged once for every order (set of samples analyzed at the same time).',

    tests: {
      heading: 'Tests ({{count}})',
      turnaroundHeading: 'Estimated turnaround time',
      turnaroundWeeks: '{{value}} weeks',
    },

    sampleConfiguration: {
      addSample: 'Add sample',
      heading: 'Sample {{no}}',
      hideRequirements: 'Hide sample requirements',
      material: 'Sample {{no}} material (matrix)',
      materialPlaceholder: 'Sample material',
      name: 'Sample {{no}} name',
      namePlaceholder: 'Sample name',
      removeSample: 'Remove the sample',
      showRequirements: 'Show sample requirements',
    },

    billing: {
      address: 'Billing address',
      addressPlaceholder: '13 Pariser Platz\n10117 Berlin',
      billingEmail: 'Billing email for PDF invoice',
      billingHeading: 'Billing information',
      contactDetailsHeading: 'Contact details',
      eInvoiceOperator: 'European e-invoicing operator ID',
      eInvoiceRoutingNumber:
        'European e-invoicing routing number (EDI/GLN/OVT)',
      nextStep: 'Next: Review order',
      paymentMethod: {
        bankTransfer: 'PDF invoice (bank transfer)',
        creditCard: 'Credit card (payment link sent by email)',
        eInvoice: 'E-invoice (Finnish customers only)',
        label: 'Your preferred payment method',
        notSelected: 'Invoice or credit card',
      },
      reference: 'Reference or Purchase Order number',
      referenceHelper:
        'If you have a reference or a purchase order number you’d like us to include on the invoice, please enter it here.',
      vatNumber: 'VAT number',
      vatNumberPlaceholder: 'FI28204611',
    },
    confirmation: {
      confirmationDetails:
        'We have successfully received your order. A confirmation has been sent to your email {{email}}.',
      confirmationEstimation:
        '{{b|We will review your order in approximately one business day.}} Meanwhile, you can prepare the samples for shipping.',
      serviceCoverage:
        'Measurlabs brings you the best laboratory testing services sourced from our global network of over 900 partner laboratories. We are based in Finland but routinely serve clients around the world.',
      shippingInstructionsLink:
        'Full instructions for sample shipment can be found on our {{link|shipping instructions page}}.',
    },
    copyCart: {
      copiedMessage: 'Link copied to clipboard',
      modal: {
        content:
          'You have opened a link to a pre-filled order. It seems that you already have an unfinished analysis order. Would you like to proceed with replacing the existing order contents?',
        heading: 'Pre-filled order',
        submitLabel: 'Replace current order',
      },
      prompt: 'Copy order',
    },
    navigation: {
      billingInformation: 'Billing information',
      cart: 'Tests and samples',
      review: 'Review order',
    },
    orderSummary: {
      paymentMethodLabel: 'Payment method:',
      priceLabel: 'Total price (VAT 0):',
      productCountPlural: 'tests',
      productCountSingular: 'test',
      sampleCountPlural: 'samples',
      sampleCountSingular: 'sample',
      sampleDeliveryLabel: 'Sample delivery:',
      serviceFee: 'Includes {{fee}} service fee',
      tatLabel: 'Estimated turnaround time:',
    },
    review: {
      acceptMarketing: 'I’d like to hear of special offers and service updates',
      acceptTerms:
        'I accept {{link|Measurlabs general terms and conditions of sales}}',
      additionalDetails: {
        heading: 'Additional details',
        notProvided: 'Not provided.',
      },
      errors: {
        apiError:
          'Failed to submit the order. Try again later or {{a|contact us!}}',
        termsNotAccepted: 'You need to accept our terms to submit the order.',
      },
      nextSteps: {
        content: `{{li|We will send you an automated order confirmation email in a few minutes. The email also contains the sample shipping instructions so you can prepare the samples for shipment.}}
          {{li|Our expert will review your order in approximately one business day to ensure the testing method is suitable for your needs and samples. We will send you an email once we have reviewed the order.}}
          {{li|You send us the samples. We will let you know by email when we have received the samples.}}
          {{li|For new customers we require advance payment. We will send you an invoice once we receive the samples. For returning customers, we'll send the invoice after the results have been reported.}}
          {{li|We will analyse the samples and send you our report with the results via email.}}
        `,
        contentCC: `{{li|We will send you an automated order confirmation email in a few minutes. The email also contains the sample shipping instructions so you can prepare the samples for shipment.}}
          {{li|Our expert will review your order in approximately one business day to ensure the testing method is suitable for your needs and samples. We will send you an email once we have reviewed the order.}}
          {{li|You send us the samples. We will let you know by email when we have received the samples.}}
          {{li|For new customers we require advance payment. We will send you the credit card payment details once we receive the samples. For returning customers, we'll send the payment instructions after the results have been reported.}}
          {{li|We will analyse the samples and send you our report with the results via email.}}
        `,
        heading: 'What happens when you submit the order',
      },
      pricingSummaryHeading: 'Pricing summary',
      sampleRequirements: {
        heading: 'Sample requirements',
        matricesHeading: 'Suitable sample matrices',
        minimumSampleAmountHeading: 'Required sample quantity',
      },
      sampleSummary: {
        fallbackName: 'Unnamed',
        heading: 'Sample summary',
        noMaterialInformation: 'No material provided',
        sampleCountPlural: '{{numSamples}} samples',
        sampleCountSingular: '{{numSamples}} sample',
        sampleNameHeading: 'Name',
        selected: 'Selected',
      },
      submitOrder: 'Submit order',
    },
  },
  categoryListing: {
    noResultsContact:
      "Can't find what you're looking for? {{link|Contact us}} and we'll help!",
  },
  contact: {
    officeNames: {
      FI: 'Headquarters (Finland)',
      GB: 'United Kingdom',
    },
    offices: 'Office locations',
  },
  contactBanner: {
    businessHours:
      'Business hours: Mon–Fri 9 AM – 5 PM Finnish time (EST/EEST)',
    heading: "Questions? We're happy to help.",
  },
  contactForm: {
    defaultInstructions: {
      1: 'How many samples do you have and what is the sample material?',
      2: 'Do you have a recurring need for these tests? If yes, how often and for how many samples at a time?',
      intro:
        'Answering the following questions helps us prepare an offer for you faster:',
    },
    heading: {
      partner: 'Become a partner laboratory',
      primary: 'Ask for an offer',
      secondary: 'Contact',
    },
    label: {
      company: 'Company',
      companySublabel:
        'We are currently only able to serve companies and other organizations.',
      email: 'Work email',
      message: 'Please describe your testing needs in detail',
      name: 'Name',
      phone: 'Work phone',
      phoneSublabel: 'Please use the international format (+1 123 555 1234).',
      services: 'What testing services do you offer?',
    },
    lead: {
      partner:
        'Fill in the form and we’ll be in touch. Alternatively, you can email us at {{email}} or call {{phone}}.',
      primary: "Fill in the form, and we'll reply in {{b|one business day}}.",
    },
    placeholder: {
      phone: '+1 123 555 1234',
    },
    questions:
      'Have questions or need help? Email us at {{email|info@measurlabs.com}} or call {{phone}}.',
    send: 'Send',
    sendErr: 'Your message was not sent. Please try again.',
    success: {
      content:
        'We have received your message and our experts will reply by the next business day.',
      heading: 'Thank you!',
    },
  },
  country: {
    ...territoriesEn,
    other: 'Other countries',
  },
  expert: {
    defaultHeading: 'Questions?',
    defaultIntro: '{{name}} is happy to answer all your questions.',
  },
  footer: {
    addressCountryFinland: 'Finland',
    businessHours: {
      fi: 'Mon–Fri 9 AM – 5 PM Finnish Time (EST/EEST)',
      gb: 'Mon–Fri 9 AM – 5 PM (GMT/BST)',
    },
    headings: {
      address: 'Visiting address',
      businessHours: 'Business hours',
      contact: 'Contact',
      courierContact: 'Recipient for courier deliveries',
      nav: 'Navigation',
      portal: 'Customer portal',
    },
    portalIntro:
      'All your projects, results, and more available in our customer portal.',
    socialLinkAriaLabel: {
      Facebook: `Measurlabs' Facebook page`,
      Instagram: `Measurlabs' Instagram page`,
      LinkedIn: `Measurlabs' LinkedIn page`,
      X: `Measurlabs' X page`,
    },
  },
  home: {
    expertCta: 'Get to know our experts',
    hero: {
      clientCount: '...and more than 700 other happy clients',
    },
  },
  industryOrMaterial: {
    biorefinery: 'Biorefinery',
    'building-materials': 'Building materials',
    chemical: 'Chemicals',
    'child-care': 'Children’s products',
    cosmetic: 'Cosmetics',
    'energy-and-fuel': 'Energy and fuels',
    environmental: 'Environmental testing',
    'food-and-feed': 'Food, feed and supplements',
    'food-contact': 'Food contact materials',
    marine: 'Marine',
    medical: 'Medical devices',
    metal: 'Metals and mining',
    packaging: 'Packaging',
    paper: 'Paper and board',
    pharma: 'Pharmaceuticals',
    polymer: 'Polymers and plastics',
    'safety-equipment': 'Safety equipment',
    semiconductors: 'Semiconductors',
    textile: 'Textiles and furniture',
    waste: 'Waste materials',
  } satisfies Record<IndustryOrMaterial, string>,
  localeSelector: {
    currency: 'Currency',
    language: 'Language',
  },
  metadata: {
    '404': {
      description:
        'The page you were looking for could not be found. It might have been removed or moved to another URL.',
      title: 'Page not found',
    },
    [StaticPage.CART]: {
      title: 'Tests and samples',
    },
    [StaticPage.CART_BILLING]: {
      title: 'Billing information',
    },
    [StaticPage.CART_REVIEW]: {
      title: 'Review order',
    },
    [StaticPage.ORDER_CONFIRMATION]: {
      title: 'Thank you for your order!',
    },
    [StaticPage.PRIVACY]: {
      description: 'Measurlabs privacy policy',
      title: 'Privacy policy',
    },
    [StaticPage.SHIPPING_INSTRUCTIONS]: {
      description: 'Sample shipping instructions',
      title: 'Shipping instructions',
    },
    [StaticPage.TERMS_SALES]: {
      description: 'Measurlabs terms and conditions',
      title: 'Terms and conditions - Sales',
    },
    [StaticPage.TERMS_ONLINE]: {
      description: 'Measurlabs terms and conditions',
      title: 'Terms and conditions - Website',
    },
    [StaticPage.PRODUCTS]: {
      description:
        'Laboratory analyses and testing services offered by Measurlabs.',
      title: 'Testing services',
    },
    [StaticPage.PROJECTS]: {
      title: 'Projects',
    },
  },
  methodPage: {
    headings: {
      faq: 'Frequently asked questions',
      sampleMatrices: 'Suitable sample matrices',
    },
  },
  nav: {
    [StaticPage.INDEX]: 'Home',
    [StaticPage.METHODS]: 'Methods',
    [StaticPage.PARTNERS]: 'For labs',
    [StaticPage.SOLUTIONS]: 'Solutions',
    [StaticPage.PRODUCTS]: 'Testing services',
    [StaticPage.CONTACT]: 'Contact',
    [StaticPage.ABOUT]: 'About',
    [StaticPage.CAREERS]: 'Careers',
    [StaticPage.PRIVACY]: 'Privacy',
    [StaticPage.SHIPPING_INSTRUCTIONS]: 'Sample shipping',
    [StaticPage.TERMS_SALES]: 'Terms and conditions',
    [StaticPage.BLOG]: 'Blog',
    [StaticPage.TEAM]: 'Team',

    cart: {
      ariaLabel: 'Analysis order',
      empty:
        'Analysis order is empty. Please add some {{link|testing services}} to the order.',
    },
  },
  newsletter: {
    emailPlaceholder: 'Your email',
    feedback: {
      generalSubscribeError:
        'Error subscribing to the newsletter. Please try again in a bit.',
    },
    submitButton: 'Join',
    subscribeCallBold: 'Keep posted.',
    subscribeCallLight: 'Join our newsletter.',
  },
  notFound: {
    errorDescription:
      'This page does not exist or it has been moved to another address. Please double check the URL.',
    instructions:
      'You can browse our testing services on the {{link|testing services page}} or by picking a category below:',
    title: 'Page not found',
  },
  partnersPage: {
    hero: {
      lead: 'Become a partner laboratory',
    },
  },
  phone: {
    numberListHeading: 'Our international service numbers',
  },
  portal: {
    collapseAll: 'Collapse all',
    completedAt: 'Completed',
    estimatedCompletion: 'Estimated completion',
    expandAll: 'Expand all',
    expert: 'Measurlabs testing expert',
    expertAccess:
      'Currently using portal in expert mode. All projects are shown.',
    files: {
      downloadError:
        'Failed to download the file. If the problem persists, please {{link|contact us}}.',
      heading: 'Files',
      noFiles: 'No files.',
      noFilesContactUs:
        'Something missing? Contact {{email|info@measurlabs.com}}.',
    },
    filters: {
      noResults: 'No projects matching your search',
      noTeam:
        'You have not been added to a team, so you can only see your own projects. Contact us if you need assistance.',
      organization: 'Organization',
      organizationTooltip:
        'Visible organization can only be changed by Measurlabs employees.',
      owner: 'Owner',
      ownerAnyone: 'Anyone',
      queryPlaceholder: 'Search project by name, note or PO...',
      selectOrganization: 'Select organization',
    },
    generalFiles: {
      heading: 'General files',
    },
    loginButton: 'Log in to the portal',
    missingProjects:
      "{{strong|Not seeing all the projects you need?}} You haven't been added to a team yet, so you are only seeing the projects where the owner is {{owner}}. To see the projects of your colleagues, please contact us at {{mailto|info@measurlabs.com}}.",
    noProjects: {
      body: 'Browse our catalog of 500+ testing services to find the right one for your needs:',
      cta: 'Browse testing services',
      footer:
        'If you think this is an error and your order history is not showing up here, please contact {{email|info@measurlabs.com}} and we’ll sort it out.',
      heading: 'It looks like you don’t have any projects yet',
    },
    orderAction: 'Order online',
    owner: 'Owner',
    projectStatus: {
      analysisInProgress: 'Analysis in progress',
      completed: 'Completed',
      offerProvided: 'Offer provided',
      samplesReceived: 'Samples received',
      waitingForReview: 'Waiting for review',
      waitingForSamples: 'Waiting for samples',
    },
    projects: 'Projects',
    reference: 'Ref',

    project: {
      namePlaceholder: 'Project name',
      referencePlaceholder: 'PO/Reference',
      rename: 'Rename',
      setName: 'Set name',
      setReference: 'Add reference',
      unnamedProject: 'Unnamed project',

      notes: {
        edit: 'Edit notes',
        heading: 'Notes',
        placeholder: 'Notes',
        set: 'Add notes',
        tooltip:
          'Tip: use notes to store useful information about the project for later or for search keywords.',
        visibilityDisclaimer:
          'The notes are visible to Measurlabs staff, but they will not be notified',
      },
    },
    soon: 'Soon',
  },
  pricing: {
    noVat: 'Excl. VAT',
    noVatLong: 'Prices excluding VAT.',
    pcs: 'pcs',
    perSample: 'per sample',
    pricePerSample: 'Price per sample',
    priceRange: 'Typical price range',
    quantity: 'Quantity',
    totalPriceLong: 'Total price (VAT 0)',
    totalPriceShort: 'Total',
    unitPrice: 'Unit price (VAT 0)',
  },
  productDetails: {
    breadcrumbRoot: 'Testing services',
    detectionLimit: 'Detection limit',
    deviceTypes: 'Device types',
    expert: 'Method expert',
    landingPageLink: 'More information about the method',
    qualitySystemFallback: 'Measurlabs validated method',
    qualitySystemValues: {
      '9001': 'ISO 9001',
      accreditedLab: 'Accredited testing laboratory',
      accreditedMethod: 'Accredited test method',
      glp: 'GLP test method',
      gmp: 'GMP test method',
      reach: 'REACH',
    } satisfies Record<QualitySystem, string>,
    qualitySystems: 'Available quality systems',
    sampleMatrices: 'Suitable sample matrices',
    sampleRequirements: 'Required sample quantity',
    standard: 'Standard',
    turnaround: 'Typical turnaround time',
    turnaroundWeeksNoEstimate: 'No estimate',
    turnaroundWeeksPlural: 'weeks after receiving the samples',
    turnaroundWeeksSingular: 'week after receiving the sample',
  },
  productFilters: {
    clear: 'Clear',
    selectedFilters: 'Filters:',
    showAll: 'Show all',
    textSearch: 'Search by text, matrix, or standard...',
    [ProductFilterType.DEVICE_TYPE]: {
      label: 'Device',
      none: 'No filterable devices',
      placeholder: 'Filter devices...',
    },
    [ProductFilterType.INDUSTRY_OR_MATERIAL]: {
      label: 'Industry or material',
      none: 'No filterable industries or materials',
      placeholder: 'Filter industries...',
    },
    [ProductFilterType.TYPE_OF_TESTING]: {
      label: 'Type of testing',
      none: 'No filterable testing types',
      placeholder: 'Filter testing types...',
    },
    [ProductFilterType.STANDARD]: {
      label: 'Standard',
      none: 'No filterable standards',
      placeholder: 'Filter standards...',
    },
  },
  productListing: {
    buyOnline: 'Order online',
    noResultsBody:
      'Note that only a fraction of our services are listed on the website. Try using less specific keywords or contact us to get access to all services.',
    noResultsCardBody:
      'Only a fraction of our services are listed on the website. Contact us to get access to all services.',
    noResultsCardHeading: 'Can’t find what you’re looking for?',
    noResultsHeading: 'No results for ”{{query}}”',
    noResultsHeadingFiltered:
      'No results for ”{{query}}” with the chosen filters',
    readMore: 'Read more',
    selfCheckoutAvailable: 'Online ordering available',
  },
  productPage: {
    otherTests: 'Other tests we offer',
    productCTABanner: {
      actionLabel: 'Request offer',
      heading: 'Price',
    },
    productConfiguration: {
      addToCart: 'Order online',
      addedToCart: {
        buyDifferentOptions: 'Buy this test with different options',
        buyOther: 'Buy other tests',
        heading: 'Test added to analysis order',
        proceed: 'Proceed to add samples',
      },
      expertReview:
        'Our experts review all orders to ensure the testing method is suitable for your needs and samples.',
      heading: 'Pricing and online order',
      quantityDiscount: 'Large batches of samples are eligible for discounts.',
      samplesNote: 'Samples are entered during checkout.',
      serviceFee: 'We also charge a {{b|service fee}} per order.',
      slugLink: {
        [LinkParameterSlug.DANGEROUS_GOODS]: {
          label: 'Read more',
          url: '/shipping/#dangerous-goods',
        },
      },
    },
  },
  productPlaceholder: {
    body: "Contact us! We're able to offer a wide range of custom testing services.",
    heading: 'Not quite what you were looking for?',
  },
  search: {
    alsoOtherMethods:
      'We are able to solve many kinds of testing needs even if you cannot find what you are looking for on our website. {{link|Contact us!}}',
    groupHeading: {
      blogPost: 'Blog posts',
      method: 'Methods',
      product: 'Testing services',
      solution: 'Solutions',
    },
    inContextHelp: 'E.g. SEM, osmolarity, ICP, ISO 1182...',
    measurement: 'Test',
    noResults: 'No search results.',
    placeholder: 'Search tests by name or standard...',
  },
  sections: {
    home: {
      founded:
        'A team of specialists ready to help. With us you always get personal service from method experts.',
    },
  },
  shareButtons: {
    ariaLabel: {
      Facebook: 'Share on Facebook',
      LinkedIn: 'Share on LinkedIn',
      X: 'Share on X',
    },
  },
  shippingInstructions: {
    dangerousGoodsDescription:
      '{{p|In addition, a substance or object which seems harmless may be classified as dangerous goods if its properties are affected by the transport conditions. For example, lithium batteries and devices containing them, as well as dry ice, are covered by the regulations for the air transport of dangerous goods. On the other hand, not all hazardous substances are classified as dangerous goods when shipped.}}{{p|The easiest way to find out if a shipment needs to be handled according to Dangerous Goods Regulations is to check the substance’s Safety Data Sheet. Section 14 of the Safety Data Sheet (Transport information) indicates if the substance has any transport restrictions. If the Safety Data Sheet is not available for a substance, it is a good idea to think about what the substance consists of and whether any component could be classified as hazardous.}}{{p|Dangerous goods must always be handled in accordance with the current regulations. Everyone who handles shipments of dangerous goods must also be trained in the subject. We will be happy to help if you have any questions!}}',
    dangerousGoodsIntro:
      '{{p|A dangerous good is any substance or material which may present a significant risk to health, safety, or property when transported. The International Civil Aviation Organization (ICAO) maintains a list of classified dangerous goods and mandatory regulations affecting their handling and transport. We use air transport to ship samples, so it is crucial to know if we are handling samples that are classified as dangerous goods.}}{{p|Many substances can be identified as dangerous based on their properties. Dangerous goods include:}}',
    dangerousGoodsListItems:
      '{{li|Explosives}}{{li|Many gases}}{{li|Flammable liquids}}{{li|Highly flammable solid substances}}{{li|Oxidizing agents}}{{li|Toxic and infectious substances}}{{li|Radioactive substances}}{{li|Corrosive substances}}',
    dangerousGoodsTitle: 'Dangerous goods shipments',
    deliveryAddressTitle: 'Delivery address',
    disposalNote:
      '{{b|Note!}} Unless otherwise agreed, we will dispose of your samples after the tests are finished. If the customer requests the return of unneeded sample material, Measurlabs can return them to the customer, at the customer’s cost and risk. Refer to {{toc|terms and conditions}} for further details.',
    instructions: {
      attachments:
        '{{b|If you have the Safety Data Sheet (SDS) for the substance, please include it in the shipment.}}',
      confirmation:
        '{{b|Print the order confirmation email and send it to us together with your samples.}}',
      courier:
        '{{b|We strongly recommend using a courier service instead of sending the samples by mail.}} Courier services are almost always faster and more reliable.',
      labeling:
        '{{b|Mark your samples clearly, preferably with printed sample markings rather than handwritten ones.}} Mark each sample with a simple, unique identifier (e.g. Sample X).',
      materials:
        '{{b|Make sure you have enough sample material for the tests.}} If you are concerned that your samples are too small, then please contact us for advice.',
      packing:
        '{{b|Pack your samples into containers that can withstand the shipping.}} If possible, avoid using material with your branding (such as boxes and packing tapes), as we aim to reuse and recycle as much packaging as we can.',
      regulations:
        '{{b|Follow the rules and regulations if you are shipping Dangerous Goods.}} Section 14 of the substance SDS contains information about transport requirements. You can find more information {{iataLink|here}}. Please contact {{packagesLink|packages@measurlabs.com}} if you have any questions.',
    },
    intro:
      'Please follow the instructions below when sending your samples to Measurlabs.',
    outsideEUNotes: {
      customsValue:
        '{{b|Samples must be declared to customs.}} To prevent shipping clearance delays, please give the value of the samples as 1 USD only. Describe the samples as {{i|”XXX sample for destructive testing”}}, where XXX is a short (1–5 word) description, understandable by a non-expert in the field.',
      label:
        'If you send samples from {{b|outside of the EU or UK area}}, please deliver the samples to our Finnish location and note the following:',
      requiredItems: {
        description:
          'An accurate goods description for each item in the shipment. What is it? What is it made of? What is it intended for?',
        eoriNumber:
          "The receiver's Economic Operator Registration and Identification (EORI) number: Measur Oy, EORI number: FI2820461-1",
        hsCode:
          'A Harmonised System (HS) code (minimum of six digits), also known as a Tariff code or a commodity code',
      },
      requiredLabel:
        'The following information needs to be included on the air waybill and the commercial invoice:',
      trackingCode:
        'Please provide us with the {{b|shipment tracking number}} so we can clear the customs as soon as possible to avoid delays. Include the Offer ID and email {{packagesLink|packages@measurlabs.com}}.',
    },
    title: 'Sample shipping instructions',
  },
  teamMemberPage: {
    blogCta: 'Check it out',
    blogFooter:
      'Read articles by our experts and guest authors on the practical implications of regulations, scientific advancements, and the ideal uses of different analysis methods.',
    blogHeading: '{{name}}’s latest articles in our blog',
    moreAbout: 'More about {{name}}',
    productFooter: 'Measurlabs offers 2000+ tests from 900+ laboratories.',
    productHeading: '{{name}}’s top testing services',
  },
  termsAndConditions: {
    heading: "Measurlabs' terms and conditions",
  },
  typeOfTesting: {
    'air-quality': 'Air quality and gases',
    biology: 'Biological testing',
    compositional: 'Compositional analysis',
    electrical: 'Electrical properties',
    elemental: 'Elements and ions',
    fire: 'Fire testing',
    mechanical: 'Mechanical testing',
    microbiology: 'Microbiological analysis',
    microscopy: 'Microscopy',
    particle: 'Particle testing',
    properties: 'Physical and chemical properties',
    regulatory: 'Regulatory tests',
    rheology: 'Rheology',
    'std-product': 'Standardized product tests',
    surface: 'Surface testing',
    thermal: 'Thermal testing',
  } satisfies Record<TypeOfTesting, string>,
  valuePropositions: {
    accuracyGuarantee: 'Result accuracy guarantee',
    competitivePrices: 'Competitive prices',
    fastTurnaround: 'Fast turnaround times',
    personalService: 'Personal service from method experts',
  },
}
