export enum StaticPage {
  ABOUT = 'about',
  CAREERS = 'careers',
  CONTACT = 'contact',
  INDEX = 'index',
  PARTNERS = 'partners',
  METHODS = 'methods',
  SOLUTIONS = 'solutions',
  NOT_FOUND = '404',
  PRIVACY = 'privacy',
  PRODUCTS = 'products',
  PROJECTS = 'projects',
  CART = 'cart',
  CART_BILLING = 'cartBilling',
  CART_REVIEW = 'cartReview',
  SHIPPING_INSTRUCTIONS = 'shipping',
  TERMS_SALES = 'terms',
  TERMS_ONLINE = 'online-terms',
  ORDER_CONFIRMATION = 'cartConfirmation',
  BLOG = 'blog',
  TEAM = 'team',
}
